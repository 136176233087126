<template>
  <modal
    name="createConversationModal"
    transition="pop-out"
    class="create-conversation-modal"
    :width="550"
    :focus-trap="true"
    :height="450"
  >
    <div class="position-relative modal-outer-container">
      <div class="modal-header border-bottom">
        <div>New Conversation</div>
        <button class="btn border-0" @click="closeModal">
          <i class="fas fa-times fa-lg"></i>
        </button>
      </div>
      <div class="body-inner-container">
        <div class="card-body">
          <form
            @submit.prevent="createConversationHelper"
            validate
            class="form"
          >
            <div class="row">
              <div class="font-weight-bold col-12 mb-1">
                <input-control
                  v-model="title"
                  wrapClass="align-items-center"
                  type="vertical"
                  :required="true"
                >
                  Conversation title
                </input-control>
              </div>
              <div class="col-12 mt-3 mt-md-0">
                <label class="col-12 font-weight-bold pt-2 pl-0">
                  Participants
                </label>
                <div class="col-12 mb-1 px-0">
                  <multiselect
                    v-model="participants"
                    :options="getUsers"
                    :loading="loading"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Select conversation participants"
                    Value="id"
                    label="name"
                    track-by="id"
                    :show-labels="false"
                    :limit="3"
                  >
                    <template slot="option" slot-scope="props">
                      <div class="d-flex align-items-center">
                        <div class="user-image-with-status">
                          <img
                            class="user-image"
                            :src="props.option.img"
                            :alt="props.option.subject"
                          />
                          <div
                            class="user-status"
                            :class="{
                              online: isOnline(props.option.id),
                              offline: !isOnline(props.option.id),
                            }"
                          />
                        </div>
                        <div class="d-flex flex-column">
                          <span class="font-weight-bold">
                            {{ props.option.name }}
                          </span>
                          <span
                            class="small font-weight-bold"
                            style="opacity: 0.7"
                          >
                            {{ props.option.role }}
                          </span>
                        </div>
                      </div>
                    </template>
                  </multiselect>
                </div>
              </div>
              <div class="small col-12 mb-1">
                Note: if you select one participant, the conversation title will
                be set to the participant's name by default and you can change
                it.
              </div>
            </div>
            <div class="text-center pt-4">
              <save :saving="createConversationLoading" classes="btn btn-theme">
                Create Conversation
              </save>
            </div>
          </form>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "CreateConversationModal",
  components: {
    Multiselect,
  },
  data() {
    return {
      title: "",
      participants: [],
      patientProviders: [],
    };
  },
  watch: {
    participants: function (val, oldVal) {
      if (!val.length && oldVal.length) {
        if (this.title == oldVal[0].subject) {
          this.title = "";
        }
      } else if (val.length == 1) {
        if (!this.title) {
          this.title = val[0].subject;
        }
      } else if (val[0] && this.title == val[0].subject) {
        this.title = "";
      }
    },
  },
  computed: {
    ...mapState({
      createConversationLoading: (state) =>
        state.chat.createConversationLoading,
      allUsers: (state) => state.security.usersWithRoles,
      loading: (state) => state.security.usersWithRolesLoading,
      onlineUsers: (state) => state.auth.onlineUsers,
      loggedInUser: (state) => state.auth.user,
    }),
    defaultAvatar: function () {
      return require("@/assets/img/avatars/profiles/avatar.jpg");
    },
    getUsers: function () {
      const isPatient = this.loggedInUser && this.loggedInUser.isPatient;
      const src = isPatient ? this.patientProviders : this.allUsers;
      return src
        .filter((user) => user.user_id != this.loggedInUser.id)
        .map((user) => {
          return {
            id: user.user_id,
            name: user.name,
            img: user.profile_picture || this.defaultAvatar,
            role: this.getRoleName(isPatient ? 2 : user.roleId),
          };
        });
    },
  },
  methods: {
    ...mapActions({
      createConversation: "chat/createConversation",
      getPatientProviders: "patients/providers/getClientProviders",
    }),
    createConversationHelper: function () {
      this.createConversation({
        title: this.title,
        participants: this.participants.map((user) => user.id),
      }).then((res) => {
        if (res) {
          this.closeModal();
          this.$emit("conversation-created");
        }
      });
    },
    isOnline: function (user_id) {
      return (this.onlineUsers || []).includes(user_id);
    },
    getRoleName: function (roleId) {
      let roleName;
      switch (roleId) {
        case 1:
          roleName = "(Admin)";
          break;
        case 2:
          roleName = "(Provider)";
          break;
        case 5:
          roleName = "(Manager)";
          break;
        default:
          roleName = "(Client)";
          break;
      }
      return roleName;
    },
    closeModal: function () {
      this.title = "";
      this.participants = [];
      this.$modal.hide("createConversationModal");
    },
  },
  beforeMount() {
    if (this.loggedInUser && this.loggedInUser.isPatient) {
      this.getPatientProviders(this.loggedInUser.patient_id).then(
        (providers) => {
          this.patientProviders = providers;
        }
      );
    }
  },
};
</script>
<style lang="scss" scoped>
@import url("./_conversation.scss");
.create-conversation-modal {
  .card-body {
    padding: 1.5rem;
  }
}
</style>
<style lang="scss">
.create-conversation-modal {
  &.v--modal-overlay {
    z-index: 1002;
  }
  .v--modal-box {
    overflow: visible !important;
  }

  .user-image-with-status {
    position: relative;
    display: flex;
    align-items: center;
    .user-image {
      object-fit: cover;
      border-radius: 100%;
      margin-right: 12px;
      height: 35px;
      width: 35px;
    }
    .user-status {
      position: absolute;
      right: 12px;
      bottom: 0;
      height: 15px;
      width: 15px;
      border-radius: 100%;
      border: 1px solid #edf2f9;
      &.online {
        background-color: #4caf50;
      }
      &.offline {
        background-color: #9ca6af;
      }
    }
  }
}
</style>
